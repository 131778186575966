@import '../../assets/scss/combined.scss';

.bulk {
  &_upload {
    padding: 4em 2em;
    background-color: rgba(0, 0, 0, 0.08);
  }

  &_org {
    padding: 0.8em;
  }

  &_error {
    padding: 0.8em;
    background-color: $red;
    color: $white;
  }
}

.k-dropzone,
.k-upload-dropzone {
  background-color: rgba(0, 0, 0, 0.08) !important;
  color: $white;
}

.k-file-single {
  background-color: $white;
}
