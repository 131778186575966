:root {
  /* 
* Primitive Variables
* Must not be used directly in css 
*/
  --brand_black: #212120;

  --brand_blue: #0383bf;
  --brand_blue__dark: #044260;
  --brand_blue__darkest: #033144;
  --brand_blue__light: #4ea8d2;
  --brand_blue__lightest: #a6d1e6;

  --brand_grey: #bebdbd;
  --brand_grey__dark: #393939;
  --brand_grey__light: #ebedef;

  --brand_white: #ffffff;
  --brand_green: #53be9e;
  --brand_red: #f05c5d;

  --status_green__dark: #189670;
  --status_red__dark: #d63737;
  --status_orange__dark: #f38b00;
  --status_orange: #ffb654;
  --status_green__alt: #a2ea66;
  --status_yellow: #ffe66f;
  --status_yellow__dark: #e3c224;
  --status_green-alt__dark: #6dca20;
  --status_purple: #7c3cdf;
  --status_purple__dark: #4a0aae;

  --app__background-color: var(--brand_grey__light);

  --app__header-background: var(--brand_blue);
  --app__header-color: var(--brand_white);

  --button-color-primary-background: var(--brand_blue);
  --button-color-primary: var(--brand_white);
  --button-hover-background-color: var(--brand_blue__lightest);

  --component-background-color: var(--brand_white);
  --component-border-color: var(--brand_grey);

  --heading-color-default: var(--brand_blue__dark);
  --heading-font-family: 'Oswald, Arial, sans-serif';
  --heading-text-page: var(--text-size-xxl);

  --radius-lg: 0.5rem;
  --radius-default: 0.25rem;
  --radius-sm: 0.125rem;

  --space-md: 1rem;
  --space-sm: 0.5rem;
  --space-xs: 0.25rem;
  --space-xxs: 0.125rem;
  --space-none: 0;

  --stroke-size-default: 1px;
  --stroke-size-thick: 2px;
  --stroke-size-heavy: 3px;
  --stroke-size-none: 0;

  --stroke-color-default: var(--brand_grey);
  --stroke-color-dark: var(--brand_grey__dark);
  --stroke-color-light: var(--brand_grey__light);

  --table-header-background-color: var(--component-background-color);
  --table-row-background-color: var(--component-background-color);
  --table-row-hover-background-color: var(--brand_blue__lightest);
  --table-footer-background-color: var(--component-background-color);

  --text-color-default: var(--brand_black);
  --text-color-disabled: var(--brand_grey__dark);
  --text-color-reverse: var(--brand_white);
  --text-color-selection: var(--text-color-default);
  --text-background-color-selection: var(--brand_blue__lightest);
  --text-size-default: 1rem;
  --text-size-xxl: 2rem;
  --text-size-xl: 1.5rem;
  --text-size-lg: 1.25rem;
  --text-size-sm: 0.75rem;
}
