.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--brand_grey__light);
  border-radius: 5px;
  margin: auto;
}
