.eyes-table {
  & .k-grid-toolbar {
    justify-content: space-between;
  }

  & .k-grid-toolbar::before {
    display: none;
  }
}

.k-button .eyes-icon {
  margin-right: var(--space-xs);
}

.k-button-group .k-button {
  padding-left: var(--space-md);
  padding-right: var(--space-md);
}
