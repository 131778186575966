.drawer-content {
  align-self: stretch;
  box-sizing: border-box;
  overflow: hidden;
}

.drawer-header {
  align-items: center;
  background-color: var(--app__header-background);
  display: flex;
  flex-flow: row;
  padding: var(--space-xs);
}

.drawer-icon {
  color: var(--app__header-color);
  padding: var(--space-sm);
}

.drawer-item {
  align-items: center;
  gap: var(--space-sm);
}

.logout-btn {
  justify-self: flex-end;
}

.app__logo-mark {
  align-content: baseline;
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.app__logo {
  height: 32px;
}

.app__name {
  border-left: var(--stroke-size-default) solid var(--stroke-color-light);
  color: var(--app__header-color);
  padding-left: var(--space-sm);
}

.k-drawer-container {
  align-items: flex-start;
  box-sizing: border-box;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  overflow: auto;
}

.k-grid {
  overflow: auto;
}

.k-grid-header {
  border-bottom-color: var(--stroke-color-dark);
  border-bottom-width: var(--stroke-size-thick);
}

.k-grid .k-table-row:not(:first-child) td {
  border-top: var(--stroke-size-default) solid var(--stroke-color-default);
}

.k-grid .k-grid-pager,
.k-grid-footer {
  border-top-color: var(--stroke-color-dark);
  border-top-width: var(--stroke-size-thick);
}
