html,
body,
#root {
  background-color: var(--app__background-color);
  box-sizing: border-box;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
}

.app__container {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.page {
  box-sizing: border-box;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding-inline-end: var(--space-md);
  padding-inline-start: var(--space-md);
}

.page-header__text {
  color: var(--heading-color-default);
  font-size: var(--heading-text-page);
  margin-block-end: var(--space-sm);
  margin-block-start: var(--space-md);
}

.dialog-header-text {
  background-color: var(--heading-color-default);
  border-radius: var(--radius-default);
  color: var(--text-color-reverse);
  font-size: var(--text-size-lg);
  margin: var(--space-none);
  padding: var(--space-sm) var(--space-md);
}

.dialog-fieldset {
  border: none;
  font-size: 18px;
  font-weight: bolder;
  padding-top: 15px;
  padding-bottom: 20px;
}

.k-filtercell .k-dropdownlist {
  background-color: var(--component-background-color);
  color: var(--text-color-default);
}

/* added to override the !import put in place by kendo for .k-button-flat */
.k-pager-numbers .k-button:not(.k-selected) {
  color: var(--text-color-default);
}

.k-pager-numbers .k-button {
  &.k-selected {
    background-color: var(--button-color-primary-background) !important;
  }

  &.k-selected .k-button-text {
    color: var(--button-color-primary);
  }
}
